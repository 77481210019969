<template>
    <div class="imagebox" :style="{...styles.height}" :title="title" @click="handleClick">
        <div class="imagebox-bg" :style="styles" :class="classes"></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ImageBox",
    data(){
        return {

        }
    },
    props:{
        src:String,
        title:String,
        height:Number,
        clickable:Boolean,
    },
    computed:{
        styles(){
            return {
                backgroundImage:"url("+this.src+")",
                height:this.height + 'px',
                backgroundSize:'1920px ' + this.height + 'px'
            }
        },
        classes(){
            return {
                "imagebox-clickable":this.clickable
            }
        }
    },
    methods:{
        handleClick(evt) {
            if(this.clickable){
                this.$emit('click', evt);
            }
        },
    }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
    @import "./ImageBox_pc.scss";
}

@media screen and (max-width: $minDeviceWidth_pc) {
    @import "./ImageBox_mobile.scss";
}
</style>